import { Injectable } from "@angular/core";
import { AppResourcesConfig } from "./app-resources.config";

@Injectable({
  providedIn: "root",
})
export class AppResourcesService {
  public static images = {
    logo: AppResourcesConfig.image("logo.png"),
    notification: AppResourcesConfig.image("notification.png"),
    profile: AppResourcesConfig.image("profile.png"),
  };

  public static logos = {
    treebu_logo: AppResourcesConfig.logo("treebu_logo.png"),
    business_logo: AppResourcesConfig.logo("business_logo.png"),
  };

  public static icons = {
    options: {
      addButton: AppResourcesConfig.icon("options/add_button.png"),
    },
    email_form: AppResourcesConfig.icon("email.png"),
    info: AppResourcesConfig.icon("info.png"),
    calendar: AppResourcesConfig.icon("calendar.png"),
    search: AppResourcesConfig.icon("search.png"),
    next: AppResourcesConfig.icon("next.png"),
    down: AppResourcesConfig.icon("down.png"),
    success: AppResourcesConfig.icon("success.svg"),
    error: AppResourcesConfig.icon("error.svg"),
    error_x: AppResourcesConfig.icon("error-x.svg"),
    facebook: AppResourcesConfig.icon("facebook.svg"),
    greenCheck: AppResourcesConfig.icon("green_check.svg"),
    edit_action: AppResourcesConfig.icon("edit_element.svg"),
    delete_action: AppResourcesConfig.icon("action_delete.svg"),
    plus_ligth: AppResourcesConfig.icon("plus_icon.svg"),
    trsh_red: AppResourcesConfig.icon("trash-red.svg"),
    in_progress: AppResourcesConfig.icon("in_progress.svg"),
    beneficiarie_light: AppResourcesConfig.icon("beneficiarie_light.svg"),
  };

  public static menuIcon = {
    dashboard: AppResourcesConfig.menuIcon("dashboard.png"),
    refill: AppResourcesConfig.menuIcon("refills.png"),
    payments: AppResourcesConfig.menuIcon("payments.png"),
    transactions: AppResourcesConfig.menuIcon("transactions.png"),
    analytics: AppResourcesConfig.menuIcon("analytics.png"),
    parametrics: AppResourcesConfig.menuIcon("parametrics.png"),
  };
}
